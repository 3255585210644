$tb-dark-theme: false;
$tb-kendo-border-radius: 4px;
$tb-primary: #0f1e85;
$tb-secondary: #666666;
$tb-info: #0058e9;
$tb-success: #37b400;
$tb-warning: #ffc000;
$tb-error: #f31700;
$tb-body-bg: #ffffff;
$tb-body-text: #424242;
$tb-heading-text: #292929;
$tb-subtle-text: #666666;
$tb-disabled-text: #8f8f8f;
$tb-base-bg: #fafafa;
$tb-hovered-bg: #f0f0f0;
$tb-selected-text: #ffffff;
$tb-kendo-button-bg: #f5f5f5;
$tb-kendo-button-text: #424242;
$tb-link-hover-text: #d6534a;
$tb-series-a: #ff6358;
$tb-series-b: #ffd246;
$tb-series-c: #78d237;
$tb-series-d: #28b4c8;
$tb-series-e: #2d73f5;
$tb-series-f: #aa46be;
$tb-component-bg: $tb-body-bg;
$tb-component-text: $tb-body-text;
$tb-base-text: $tb-body-text;
$tb-hovered-text: $tb-base-text;
$tb-selected-bg: $tb-primary;
$tb-link-text: $tb-primary;

$dark-theme: $tb-dark-theme;
$kendo-border-radius: $tb-kendo-border-radius;
$primary: $tb-primary;
$secondary: $tb-secondary;
$info: $tb-info;
$success: $tb-success;
$warning: $tb-warning;
$error: $tb-error;
$body-bg: $tb-body-bg;
$body-text: $tb-body-text;
$heading-text: $tb-heading-text;
$subtle-text: $tb-subtle-text;
$disabled-text: $tb-disabled-text;
$base-bg: $tb-base-bg;
$hovered-bg: $tb-hovered-bg;
$selected-text: $tb-selected-text;
$kendo-button-bg: $tb-kendo-button-bg;
$kendo-button-text: $tb-kendo-button-text;
$link-hover-text: $tb-link-hover-text;
$series-a: $tb-series-a;
$series-b: $tb-series-b;
$series-c: $tb-series-c;
$series-d: $tb-series-d;
$series-e: $tb-series-e;
$series-f: $tb-series-f;
$component-bg: $tb-component-bg;
$component-text: $tb-component-text;
$base-text: $tb-base-text;
$hovered-text: $tb-hovered-text;
$selected-bg: $tb-selected-bg;
$link-text: $tb-link-text;
$font-family: inherit;
$font-size: 14px;
$font-weight-normal: 400;
$line-height: 1.4285714286;

$enable-gradients: true;

$tb-typography: (
  default-typography: (font-family: 'inherit',
    font-size: 14px,
    font-weight: 400,
    line-height: 1.4285714286,
  ),
);

@mixin typography-classes($typography) {
  @each $selector, $property in $typography {
    &-#{$selector} {
      @each $propName, $propValue in $property {
        #{$propName}: #{$propValue};
      }
    }

    &-#{$selector}-important {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue !important;
      }
    }
  }
}

$tb-effects: (
  tb-internal-none-effects: (box-shadow: (none,
    ),
    filter: (none,
    ),
    backdrop-filter: (none,
    ),
  ),
);

@mixin effects-classes($effects) {
  @each $selector, $property in $effects {
    &-#{$selector} {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue;
      }
    }

    &-#{$selector}-important {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue !important;
      }
    }
  }
}


@import '@progress/kendo-theme-default/dist/all';
@import '@progress/kendo-font-icons/dist/index.css';


.k-icon-black {
  color: rgba(0, 0, 0, 50%) !important;
}

.k-icon-blue {
  color: #00b3a2 !important;
}

.k-sort-icon > .k-icon-wrapper-host > .k-svg-i-sort-asc-small,
.k-sort-icon > .k-icon-wrapper-host > .k-svg-i-sort-desc-small
{
  display: none !important;
}
.k-list-item.k-focus,
.k-list-item.k-selected
{
  background-color: #00b3a1 !important;
}
.k-grid-filter-menu.k-active{
  border-radius: 8px !important;
}
.k-grid-filter-menu{
  background-color: rgba(0, 0, 0, 0) !important
}
.k-grid-filter-menu.k-active{
  background-color: rgba(0, 0, 0, 0) !important
}
.k-grid-filter-menu> .k-icon-wrapper-host > .k-svg-i-filter{
  color: rgba(0, 0, 0, 50%) !important;
}
.k-grid-filter-menu.k-active > .k-icon-wrapper-host > .k-svg-i-filter{
  color: #00b3a1 !important;
}
.k-button-solid-primary{
  background-color: #00b3a1 !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
}
.k-calendar-td.k-today{
  color: #00b3a2 !important;
}
.k-calendar-td.k-selected.k-focus > span{
  background-color: #00b3a2 !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
}
.k-calendar-nav-today{
  color: #00b3a2 !important;
}
.k-calendar-navigation-marker{
  color: #00b3a2 !important;
}
.k-reset > li:hover{
  color: #00b3a2 !important;
}
.k-actions{
  display: flex;
  flex-direction: row-reverse;
}