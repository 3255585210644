// Main Alberta Colors

:root {
  // colors for primary and detail views
  --alberta-color-primary: #001737;
  --alberta-color-primary-contrast: white;
  --alberta-color-primary-shade: #182b49;

  --alberta-color-secondary: #00b3a2;
  --alberta-color-secondary-contrast: white;
  --alberta-color-secondary-shade: #00cfbc;

  // colors for modal and edit views
  --alberta-color-primary-variant: #0f1e85;
  --alberta-color-primary-variant-contrast: white;
  --alberta-color-primary-variant-shade: rgba(0, 0, 0, 0.38);

  --alberta-color-secondary-variant: #2680eb;
  --alberta-color-secondary-variant-contrast: white;
  --alberta-color-secondary-variant-shade: #5ab7ff;

  // background & surface
  --alberta-color-background: #ffffff;
  --alberta-color-surface: #f5f5f5;
  --alberta-color-desktop-background: #d6d6d6;
  --alberta-color-grey-medium: #989aa2;
  // warning & alert & success
  --alberta-color-danger: #cf0000;
  --alberta-color-warning: #ff0000;
  --alberta-color-success: #02b500;
  --alberta-color-alert: #ffd014;

  // icon
  --alberta-color-icon: #acacac;
  --alberta-color-icon-variant: #b9c1db;

  // lists
  --alberta-color-mouseover: #f2f5f3;
  --alberta-color-border: rgba(0, 0, 0, 0.12);

  // Chips
  --alberta-color-chip: #f0f9f8;
  --alberta-color-chip-border: #9ce1da;
  --alberta-color-chip-text: #00b3a2;
  --alberta-color-chip-remove-button-background: #00998a;
  --alberta-color-chip-remove-button: white;
  --alberta-color-chip-active-background: #e4f0fd;
  --alberta-color-chip-border-grey: #d0d0d0;
  --alberta-color-chip-color-grey: #2b2b2b;
  --alberta-color-chip-white-background: white;

  //info-box
  --alberta-color-info-box-info: #fbb51a;
  --alberta-color-info-box-info-background: #fff8e7;
  --alberta-color-info-box-warning: #cf0000;
  --alberta-color-info-box-warning-background: #fae5e5;
  --alberta-color-info-box-success: #44cc00;
  --alberta-color-info-box-success-background: #def3d4;

  // Label
  --alberta-color-label-text: #00b3a2;
  --alberta-label-warning: #cf0000;
  --alberta-label-info: #ffa200;
  --alberta-label-note: #001737;
  --alberta-label-dark-color: #001737;
  --alberta-label-success: #02b500;

  // inputs
  --alberta-color-inputs-focused: #e8e9ee;
  --alberta-color-inputs-hoverd: #fbfbfd;
  --alberta-color-inputs-disabled: #959595;
  --alberta-color-inputs-defaultIconColor: #e1e1e1;
  --alberta-color-inputs-description: #b8b8b8;
  --alberta-color-inputs-time-picker-btn-hover: rgba(25, 115, 110, 0.3);
  --alberta-color-human-body-part-strokes: black;

  // button
  --alberta-color-button-hover: #f5f9ff;
  --alberta-color-button-disabled: #a2a2a2;
  --alberta-color-button-disabled-border: #d0d0d0;
  --alberta-color-button-border: #bdd9f9;
  --alberta-color-button-ripple: #57a8ff;
  --alberta-color-button-focus-background: #e4f0fd;
  --alberta-color-delete-button-color: #cf0000;
  --alberta-color-delete-button-background: #f9eded;
  --alberta-color-delete-button-border: #fb8989;
  --alberta-color-delete-button-hover: #fb898946;

  // search-filter-bar
  --alberta-color-search-filter-bar-background: #f8f9fd;
  --alberta-color-search-filter-bar-background-input: white;
  --alberta-color-search-filter-bar-secondary: #cdd0d7;
  --alberta-color-search-filter-bar-color: #081647;
  --alberta-color-search-clear-icon: #9a9dab;

  // patient-list-header
  --alberta-color-list-filtered-count-background: #f1f9f8;
  --alberta-color-list-filtered-count-text: #30998a;

  // select modal
  --alberta-color-select-modal-background: white;
  --alberta-color-select-modal-text: #2b2b2b;
  --alberta-color-select-modal-footer-btn: white;
  --alberta-color-select-modal-line: #e8e8e8;

  // wizard page
  --alberta-color-wizard-button-hover: #5ab7ff;
  --alberta-color-wizard-button-disalbed: #cccccc;
  --alberta-color-wizard-button-disalbed-color: #8e8e8e;
  --alberta-color-wizard-footer-bar: #fafafa;

  // Login
  --alberta-color-login-background: #127c77;

  // Dashboard
  --alberta-color-dashboard-background: #ffffff;
  --alberta-color-dashboard-user: #8990a5;
  --alberta-color-dashboard-user-contrast: white;
  --alberta-color-dashboard-riders-back-line: #d5dbee;
  --alberta-color-dashboard-next-termin-title: #2b2b2b;
  --alberta-color-dashboard-next-termin-hover: #f4f4f4;
  --alberta-color-dashboard-next-termin-current-hover: #dcf3f0;
  --alberta-color-dashboard-next-termin-location: #6a6a6a;
  --alberta-color-dashboard-next-termin-border: #DFE2EC;
  --alberta-color-dashboard-next-termin-card-shadow-color: rgba(131, 146, 165, 0.21);
  --alberta-color-dashboard-next-termin-background: #f0f9f8;
  --alberta-color-dashboard-next-termin-time-notification: white;
  --alberta-color-dashobard-next-termin-middle-border: #c1c7db;
  --alberta-color-dashboard-end-of-range-10-days: #ffa200;

  // Careproposal and Order
  --alberta-color-careproposal-warning: #ffb300;
  --alberta-color-careproposal-icon-button: #9fabb7;
  --alberta-color-careproposal-shopping-cart-options: #0080ff;
  --alberta-color-order-text: #3e4545;
  --alberta-color-order-item-button: #dadae3;
  --alberta-color-expand-button-hover: #9f9f9f;
  --alberta-color-careproposal-history-text: #404344;
  --alberta-color-careproposal-history-user-text: #7d7d7d;
  --alberta-color-careproposal-selected-standard-careproposal: #ebebeb;

  // integratedCare and singleOrder
  --alberta-color-single-order-edit-icon: #212b36;
  --alberta-color-single-order-additional-info: #9f9f9f;
  --alberta-color-single-order-title: #0080ff;

  //directOrder
  --alberta-color-invoice-dash: #2b2b2b33;

  //crossTherapy and document
  --alberta-color-frame: #2b2b2b33;
  --alberta-color-billing-summary-background: #f7f7fa;
  --alberta-color-billing-summary-box-shadow: #00000029;
  --alberta-color-document-article-selected-background: #f2f9ff;

  // ERP Order Status
  --alberta-color-erp-order-status-cancled: #acacac;
  --alberta-color-erp-order-status-delivered: #44cc00;
  --alberta-color-erp-order-status-bookedAndDelivered: #2b2b2b;
  --alberta-color-erp-order-status-delayed: #ff003d;
  --alberta-color-erp-order-status-partlyDelyed: #ff5400;
  --alberta-color-erp-order-status-pending: #ffbd00;

  // tracking button
  --alberta-color-tracking-button-border: #00b3a259;
  --alberta-color-tracking-button-background: #e5faf8;
  --alberta-color-tracking-button-color: #00b3a2;

  // Attachments
  --alberta-color-preview-background: #f7f7fa;

  // Settings
  --alberta-color-settings-database-icon: #e4e4e4;
  --alberta-color-settings-online: #00cf00;
  --alberta-color-settings-offline: #cf0000;
  --alberta-color-settings-count-data-sets: #353a3a;

  // Notification
  --alberta-color-notification-item-background: white;
  --alberta-color-notification-item-text: #2b2b2b;
  --alberta-color-notification-item-buttons: #f9fafa;
  --alberta-color-notification-clear-button-text: #8392a5;

  // Appiontments
  --alberta-color-appointment-calc-duration: #0080ff;

  // Calendar settings
  --alberta-color-calendar-settings-card-border: #c1c7db;
  --alberta-color-calendar-settings-card-shadow: rgba(131, 146, 165, 0.27);

  // Therapy Tree
  --alb-therapy-tree-therapy-background: #fbfbfd;
  --alb-therapy-tree-therapy-background-hover: #ececec;

  // Rating Stars
  --alberta-color-rating-star-actived: #ffbd00;
  --alberta-color-rating-star-deactived: #c0c8d1;
  --alberta-color-rating-star-superior: #ff5400;
  --alberta-color-rating-star-inferior: #001737;

  // Tab Control
  --alberta-color-tab-control-background: #ededee;
  --alberta-color-tab-control-text-color: #4b4b4f;
  --alberta-color-tab-control-background-selected: #f0f9f8;
  --alberta-color-tab-control-color-selected: #00998a;
  --alberta-color-tab-control-border-selected: #9ce1da;

  // Chat List Entry
  --alberta-color-chat-list-entry-label: #d9d9d9;
  --alberta-color-chat-list-entry-label-background: #fafafa;
  --alberta-color-chat-list-entry-label-marked: #00998a;
  --alberta-color-chat-list-entry-label-color: #f0f9f8;

  // Chat
  --alberta-color-go-to-patient-button: #0080ff;
  --alberta-color-go-to-patient-secondary-button: #0959e2;

  // Therapy colors
  // unkonwn
  --alberta-therapy-unkown: #acacac;
  --alberta-therapy-unkown-secondary: #acacac;
  // parenteral feeding
  --alberta-therapy-parenteral_feeding: #00cf00;
  --alberta-therapy-parenteral_feeding-secondary: #bef2be;
  // enteral nutrition
  --alberta-therapy-enteral_nutrition: #ffd014;
  --alberta-therapy-enteral_nutrition-secondary: #ffeeae;
  // enterostomy
  --alberta-therapy-enterostomy: #7700ff;
  --alberta-therapy-enterostomy-secondary: #d2acff;
  // tracheostomy
  --alberta-therapy-tracheostomy: #0080ff;
  --alberta-therapy-tracheostomy-secondary: #a6d2ff;
  // incontience
  --alberta-therapy-incontinence: #00dfcb;
  --alberta-therapy-incontinence-secondary: #c0faf4;
  // wound
  --alberta-therapy-wound: #ae00a2;
  --alberta-therapy-wound-secondary: #e1a6df;
  // iv- therapy
  --alberta-therapy-ivtherapy: #00cf00;
  --alberta-therapy-ivtherapy-secondary: #bef2be;
  // artificial respiration
  --alberta-therapy-artificial_respiration: #0080ff;
  --alberta-therapy-artificial_respiration-secondary: #a6d2ff;
  // other:
  --alberta-therapy-other: #acacac;
  --alberta-therapy-other-secondary: #acacac;

  --alberta-resmed-tenant-rmd-text: #00b500;

  --alberta-resmed-tenant-rmt-text: #3850e7;

  // Status colors
  --alberta-color-status-success: #02b500;
  --alberta-color-status-info: #fbb51a;
  --alberta-color-status-warning: #cf0000;
  --alberta-color-status-unknown: #2b2b2b;
}