// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: var(--alberta-color-secondary);
  --ion-color-primary-rgb: 0, 179, 162;
  --ion-color-primary-contrast: var(--alberta-color-secondary-contrast);
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: var(--alberta-color-secondary-shade);
  --ion-color-primary-tint: var(--alberta-color-secondaryy);

  // todo remove ion-color dark and light
  // dark
  --ion-color-dark: #001737;
  --ion-color-dark-rgb: 23, 55, 1;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #001737;
  --ion-color-dark-tint: #001737;

  // light
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
